import { apiClient } from '../../../../helpers/apiHelperV2';
import { FETCH_CART_FAILURE, FETCH_CART_REQUEST, FETCH_CART_SUCCESS, SET_CART } from '../../../actionTypes';
import { shouldFetch } from '../../../fetchStatus';
import { cartResponseSelector } from '../../../selectors/standort/session/cart';
import { categoryUrlSelector, standortUrlSelector } from '../../../selectors/url';
import { sessionIdSelector } from '../../../selectors/auth';
import { toast } from '../../../../helpers/toastHelper';

export const fetchCartIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot fetch cart server-side.');

        const standortUrl = standortUrlSelector(state);
        const cartResponse = cartResponseSelector(state);

        if (shouldFetch(cartResponse.status)) {
            dispatch({
                type: FETCH_CART_REQUEST,
                payload: { standortUrl, sessionId },
            });
            try {
                const res = await apiClient.get(`/api/v1/standorte/${standortUrl}/carts/${sessionId}`);

                dispatch({
                    type: FETCH_CART_SUCCESS,
                    payload: { standortUrl, sessionId, cart: res.data.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_CART_FAILURE,
                    payload: { standortUrl, sessionId, error },
                });
            }
        }
    };
};
export const addProduct = ({ product, quantity, buttonType }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const categoryUrl = categoryUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot add product server-side.');

        try {
            product.bezeichnung = product.bezeichnung.replace(/<[^>]+>/gm, "")
            const { data } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/addProduct`, {
                product,
                quantity,
                categoryUrl,
                buttonType
            });

            toast({ message: 'Produkt wurde erfolgreich hinzugefügt', status: 'success' });

            dispatch({
                type: SET_CART,
                payload: { cart: data.data, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err, message: err.response?.data?.data });
        }
    };
};
export const addService = ({ service }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot add service server-side.');

        try {
            const { data } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/addService`, {
                service,
            });
            const cart = data.data;
            toast({ message: 'Service wurde erfolgreich hinzugefügt', status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart: cart, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err });
        }
    };
};
export const addReifen = ({ reifen, quantity }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot add tire server-side.');

        try {
            const { data } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/addReifen`, {
                reifen,
                quantity,
            });
            toast({ message: 'Die Reifen wurden erfolgreich hinzugefügt', status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart: data.data, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err });
        }
    };
};
export const addRim = ({ selectedRim, selectedVariant, quantity, carId }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot add rim server-side.');

        try {
            const { data } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/addRim`, {
                selectedRim,
                selectedVariant,
                quantity,
                carId,
            });
            toast({ message: 'Die Felgen wurden erfolgreich hinzugefügt', status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart: data.data, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err });
        }
    };
};
export const removeProduct = ({ product }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot remove product server-side.');

        try {
            const { data } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/removeProduct`, {
                product,
            });
            toast({ message: 'Produkt wurde erfolgreich entfernt', status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart: data.data, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err });
        }
    };
};
export const updateItem = ({ product }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot update product server-side.');

        try {
            const { data } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/updateProduct`, {
                product,
            });
            toast({ message: 'Menge wurde erfolgreich angepasst', status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart: data.data, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err });
        }
    };
};

// add coupon to cart
export const addCoupon = ({ couponCode }) => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot update product server-side.');
        if (!couponCode) return;

        try {
            const {
                data: { cart, message },
            } = await apiClient.put(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/coupons/${encodeURIComponent(couponCode)}`);
            
            toast({ message, status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err, message: err?.response?.data?.message });
        }
    };
};

export const deleteCoupon = () => {
    return async function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);
        const sessionId = sessionIdSelector(state);
        if (!sessionId) return console.warn('Cannot update product server-side.');

        try {
            const {
                data: { cart, message },
            } = await apiClient.delete(`/api/v1/standorte/${standortUrl}/carts/${sessionId}/coupons`);
            toast({ message, status: 'success' });
            dispatch({
                type: SET_CART,
                payload: { cart, standortUrl, sessionId },
            });
        } catch (err) {
            toast({ err });
        }
    };
};
