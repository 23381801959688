import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CartLink from '../Header/CartLink';
import ContactModal from '../Anfrage/ContactModal';
import Search from '../Search/Search';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import Button from '../form-elements/Button';
import { InternalLink } from '../form-elements/Link';
import { userResponseSelector } from '../../../modules/selectors/auth';
import { signout } from '../../../modules/actions/user';
import VehicleSelectionToggle from '../VehicleSelection/VehicleSelectionToggle';
import { markenStandortHasDatSelector, markenstandortHasFahrzeugSpezifischeSucheSelector } from '../../../modules/selectors/standort/markenStandort';
import { hideLoginSelector } from '../../../modules/selectors/standort/theme';
import MerklistenLink from '../../routes/Merkliste/MerklistenLink';
import StandortLink from '../VHost/StandortLink';

function SubNavRight({ showMobileSearchbar, setShowMobileSearchbar }) {
    const dispatch = useDispatch();
    const userResponse = useSelector(userResponseSelector);
    const hasDat = useSelector(markenStandortHasDatSelector);
    const hideLogin = useSelector(hideLoginSelector);
    const hasFSS = useSelector(markenstandortHasFahrzeugSpezifischeSucheSelector)

    return (
        <ul className="uk-margin-auto-left uk-margin-remove-bottom uk-subnav uk-flex uk-flex-middle uk-position-relative">
            {!showMobileSearchbar && 
            <>
                <li className="uk-hidden@l uk-padding-remove-left">
                    <Search />
                </li>
                <li className="uk-visible@l">
                    <Search />
                </li>
            </>}
           {hasDat && hasFSS && (
                <li>
                    <VehicleSelectionToggle />
                </li>
            )}
            {!showMobileSearchbar && (
                <li>
                    <ContactModal>
                        {({ openModal }) => (
                            <Button modifier="link" onClick={openModal}>
                                <span data-uk-icon="icon: mail; ratio: 1.5" />
                            </Button>
                        )}
                    </ContactModal>
                </li>
            )}
            <ReduxSuspense response={userResponse} fallback={null} error={null}>
                {user => {
                    if (!user || showMobileSearchbar) return null;
                    return (
                        <li>
                            <Button modifier="link" onClick={() => dispatch(signout())}>
                                <span data-uk-icon="icon: sign-out; ratio: 1.5" />
                            </Button>
                        </li>
                    );
                }}
            </ReduxSuspense>
            {!hideLogin && (
                <li>
                    <StandortLink to="/account">
                        <span data-uk-icon="icon: user; ratio: 1.5" />
                    </StandortLink>
                </li>
            )}
            <li>
                <MerklistenLink />
            </li>
            <li>
                <CartLink />
            </li>
        </ul>
    );
}

export default SubNavRight;
