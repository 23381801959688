import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import Button from '../form-elements/Button';
import { useSearch } from './useSearch';

function Search() {
    const {
        suggestions,
        searchValue,
        setSearchValue,
        executeSearch,
        handleSuggestionsFetchRequested,
        handleSuggestionsClearRequested,
        handleSearchValueChange,
        handleSuggestionSelected,
    } = useSearch();

    const [isListening, setIsListening] = useState(false);
    const [recognition, setRecognition] = useState(null);

    // Use useEffect to initialize SpeechRecognition on the client side
    useEffect(() => {
        if (typeof window !== "undefined") { // Check if window is defined
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            if (SpeechRecognition) {
                const rec = new SpeechRecognition();
                rec.continuous = false;
                rec.lang = "de-DE"; // Set language to German (change as needed)
                rec.interimResults = false;
                
                rec.onstart = () => setIsListening(true);
                rec.onend = () => setIsListening(false);
                

                rec.onresult = (event) => {
                    const newValue = event.results[0][0].transcript;
                    setSearchValue(newValue);
                    executeSearch(newValue); // Auto-search
                };

                setRecognition(rec);
            }
        }
    }, []);

    const toggleListening = () => {
        if (recognition) {
            if (isListening) {
                recognition.stop(); // Stop the recognition
                setIsListening(false);
            } else {
                recognition.start(); // Start the recognition
            }
        }
    };

    return (
        <form
            className="uk-search uk-search-default uk-width-1-1"
            onSubmit={evt => {
                evt.preventDefault();
                executeSearch(searchValue);
            }}
        >
            <Button modifier="link" className="uk-search-icon-flip uk-icon uk-search-icon" onClick={() => executeSearch(searchValue)}>
                <i data-uk-icon="search" />
            </Button>

            <Button
                modifier="link"
                className="uk-search-icon-flip uk-margin-large-right uk-search-icon uk-icon"
                onClick={toggleListening}
                disabled={!recognition}
            >
                <i data-uk-icon="microphone" className={isListening ? "uk-text-danger" : ""} /> 
            </Button>

            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                onSuggestionSelected={handleSuggestionSelected}
                getSuggestionValue={suggestion => suggestion.title}
                renderSuggestion={suggestion => suggestion.title}
                inputProps={{
                    placeholder: 'Suche ...',
                    value: searchValue,
                    onChange: handleSearchValueChange,
                }}
                theme={{
                    input: 'uk-search-input uk-form-small uk-border-pill uk-width-medium@l',
                    suggestionsContainer: 'uk-drop uk-drop-bottom-left uk-card uk-card-small uk-card-default uk-card-body',
                    suggestionsContainerOpen: 'uk-open',
                    suggestionsList: 'uk-nav uk-nav-default uk-margin-remove',
                    suggestionHighlighted: 'uk-link',
                }}
            />
        </form>
    );
}

export default Search;
