import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { merklistenSelector } from '../../../modules/selectors/merkliste';
import StandortLink from '../../components/VHost/StandortLink';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { fetchMerklistenProductsIfNeeded } from '../../../modules/actions/merkliste';
import { frontloadConnect } from 'react-frontload';
import { userSelector } from '../../../modules/selectors/auth';

const MerklistenLink = ({ merkliste, markenStandort }) => {

    const checkMerklistenLength = useCallback(() => {
        if (merkliste !== undefined && merkliste.length > 0) {
            return merkliste.filter(entry => entry.standort === markenStandort._id).length
        } else {
            return 0;
        }
    }, [merkliste, markenStandort]);

    return (

        <StandortLink to="/merkliste">
            <div className="uk-position-relative">
                <span data-uk-icon="icon: heart; ratio: 1.5" />
                {checkMerklistenLength() > 0 &&
                    <div className="uk-badge uk-position-absolute" style={{ height: 20, minWidth: 20, top: -8, right: -8 }}>
                        {checkMerklistenLength()}
                    </div>
                }
            </div>
        </StandortLink>
    );

};

const frontload = async ({ fetchMerklistenProductsIfNeeded }) => {
    await fetchMerklistenProductsIfNeeded();
};

const mapStateToProps = state => {
    return {
        merkliste: merklistenSelector(state),
        markenStandort: markenStandortSelector(state),
        user: userSelector(state),
    }
};

const mapDispatchToProps = { fetchMerklistenProductsIfNeeded };

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(MerklistenLink));


